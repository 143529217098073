import React from "react"
import {Link} from "gatsby"

import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import CheckoutButton from '../styles/PrimaryButton'
import ProductCard from '../styles/ProductCard'

const OneOnOneConsultProduct = () => {
   return (
      <ProductCard>
         <CardContent>
            <Typography component="h2" variant="h5" align="center">
               One on One Coaching
            </Typography>
            <Typography variant="subtitle1" align="center">
               Contact for Pricing
            </Typography>
            <Typography variant="body1">
               Goal specific online coaching. Initial consultation and monthly
               programming with weekly check ins. Taylored packages available.
               Contact for more information and pricing.
            </Typography>
         </CardContent>
         <CardActions>
             <Link to={'/contact'} style={{textDecoration: `none`, width: "100%"}}>
               <CheckoutButton
                  variant="contained"
                  size="large"
               >
                  Get More Information
               </CheckoutButton>
            </Link>
         </CardActions>
      </ProductCard>
   )
}

export default OneOnOneConsultProduct;