import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import SectionHeader from '../SectionHeader'
import OneOnOne from './OneOnOne'
import Merchandise from './MerchSkus'
import Templates from './TemplateSkus'
import SkuContainer from './SkuContainer'

import bgTexture from "../../images/concrete-wall-2.png"

const BGContainer = styled.div`
  background-color: ${props => props.theme.offWhite};
  background-image: url(${bgTexture});
`

const SkusContainer = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-evenly;
   padding: 0 0 1rem 0;
`

const Skus = ({stripe}) => {
   const data = useStaticQuery(graphql`
      query SkusForProduct {
         skusConsultation: allStripeSku (filter: {product: {metadata: {type: {eq: "consultation"}}}}) {
            edges {
               node {
                  id
                  currency
                  price
                  attributes {
                     name
                  }
                  product {
                     metadata {
                        description
                        type
                     }
                  }
               }
            }
         },
         skusCoaching: allStripeSku (filter: {product: {metadata: {type: {eq: "coaching"}}}}){
            edges {
               node {
                  id
                  currency
                  price
                  attributes {
                     name
                  }
                  product {
                     metadata {
                        description
                        type
                     }
                  }
               }
            }
         }
      }
   `)
   const consultSkus = data.skusConsultation.edges
   const coachingSkus = data.skusCoaching.edges
   return (
      <BGContainer>
         <SectionHeader title="Templates"/>
         <Templates stripe={stripe} />

         <SectionHeader title="Coaching"/>
         <SkusContainer id="coaching">
            {
               coachingSkus.map(({ node: sku }) => (
                  <SkuContainer key={sku.id} sku={sku} stripe={stripe} />
               ))
            }
            <OneOnOne />
         </SkusContainer>

         <SectionHeader title="Consultation"/>
         <SkusContainer id="consultation">
            {
               consultSkus.map(({ node: sku }) => (
                  <SkuContainer key={sku.id} sku={sku} stripe={stripe} />
               ))
            }
         </SkusContainer>

         <SectionHeader title="Merchandise"/>
         <Merchandise stripe={stripe} />
      </BGContainer>
   )
}

export default Skus