import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Typography from "@material-ui/core/Typography"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"

import CheckoutContainer from "./StripeCheckoutContainer"
import ProductCard from "../styles/ProductCard"
import ImageContainer from "../styles/ImageContainer"
import { formatPrice } from "./product-calcs"

const StyledContent = styled(CardContent)`
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
`

const MerchCard = ({stripe, product}) => {
   const [selectedSku, setSelectedSku] = useState('')
   const handleChange = e => setSelectedSku(e.target.value)
   return (
      <ProductCard>
         <StyledContent>
            <ImageContainer>
               { product.image && <Img fixed={product.image} /> }
            </ImageContainer>
            <div>
               <Typography component="h2" variant="h5" align="center"> {product.name} </Typography>
               <Typography variant="subtitle1" color="initial" align="center"> {formatPrice(product.price, "cad")} </Typography>
               <Typography variant="body1">{product.description}</Typography>
               <FormControl  fullWidth variant="outlined">
                  <InputLabel id="select-size-label">Select Size</InputLabel>
                  <Select
                     labelId="select-size-label"
                     id="select-size"
                     onChange={handleChange}
                     value={selectedSku}
                     labelWidth={80}
                  >
                     <MenuItem value={""}>   </MenuItem>
                     {
                        product.skus.map( ({node:sku}) => (
                           <MenuItem key={sku.id} value={sku.id}>{sku.attributes.name}</MenuItem>
                        ))
                     }
                  </Select>
               </FormControl>
            </div>
         </StyledContent>
         <CardActions>
            <CheckoutContainer stripe={stripe} skuId={selectedSku} disabled={selectedSku ? false : true} />
         </CardActions>
      </ProductCard>
   )
}

export default MerchCard