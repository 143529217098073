import React, { useState } from "react"
import Img from "gatsby-image"

import Typography from "@material-ui/core/Typography"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

import ProductCard from "../styles/ProductCard"
import CheckoutContainer from "./StripeCheckoutContainer"
import {formatPrice} from "./product-calcs"


const TemplateCard = ({ sku, stripe }) => {
   const [open, setOpen] = useState(false);
   const image = sku.localFiles ? sku.localFiles[0].childImageSharp.fluid : null
   return (
    <ProductCard>
      <CardContent>
        <Typography component="h2" variant="h5" align="center">
          {sku.attributes.name}
        </Typography>
        <Typography variant="subtitle1" color="initial" align="center">
          {formatPrice(sku.price, sku.currency)}
        </Typography>
        <Typography variant="body1">
          {sku.product.metadata.description}
        </Typography>
      </CardContent>
      {
         image &&
            <Dialog open={open} onClose={()=>setOpen(false)} fullWidth style={{padding: '16px'}}>
               <Img fluid={image} />
               <DialogActions>
                  <Button onClick={()=>setOpen(false)}> Close </Button>
               </DialogActions>
            </Dialog>
      }
      <CardActions>
        { image &&
          <Button
            variant='outlined'
            size='large'
            onClick={() => setOpen(true)}
            style={{padding: `8px 22px`}}
          >Preview</Button> }
        <CheckoutContainer stripe={stripe} skuId={sku.id} />
      </CardActions>
    </ProductCard>
  )
}

export default TemplateCard
