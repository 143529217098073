import React from "react"
import Product from './SkuCardProduct'

const SkuContainer = class extends React.Component {
   async redirectToCheckout(event, sku, quantity =1 ) {
      event.preventDefault()
      const { error } = await this.props.stripe.redirectToCheckout({
        items: [{ sku, quantity }],
        successUrl: `grym.co.uk/purchase-success/`,
        cancelUrl: `grym.co.uk/store`,
        billingAddressCollection: `required`,
      })
      if (error) {
         console.warn("Error:", error)
      }
   }
   render() {
      const sku = this.props.sku;
      return (
         <Product sku={sku} onClick={event => this.redirectToCheckout(event, sku.id)} />
      )
   }
}

export default SkuContainer
