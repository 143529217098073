import React from "react"
import CheckoutButton from "../styles/PrimaryButton"

const CheckoutContainer = class extends React.Component {
  async redirectToCheckout(event, sku, quantity = 1) {
    event.preventDefault()
    const { error } = await this.props.stripe.redirectToCheckout({
      items: [{ sku, quantity }],
      successUrl: `https://grym.co.uk/purchase-success/`,
      cancelUrl: `https://grym.co.uk/store`,
      billingAddressCollection: `required`,
    })
    if (error) {
      console.warn("Error:", error)
    }
  }
  render() {
    const id = this.props.skuId
    const disabled = this.props.disabled ? this.props.disabled : false
    return (
       <CheckoutButton
         onClick={event => this.redirectToCheckout(event, id)}
         variant="contained"
         fullWidth
         size="large"
         disabled={disabled}
       >
          Buy Now!
       </CheckoutButton>
    )
  }
}

export default CheckoutContainer;
