export const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
  return numberFormat.format(price)
}


export const getData = (data) => {
   let merchandise = []
   const productIds = getProductIds(data)
   productIds.map( (prodID,i) => {
      let productSkus = data.filter(sku => sku.node.product.id === prodID)
      let imageData = checkForLocalFiles(productSkus)
      return merchandise.push({
        skus: productSkus,
        id: prodID,
        name: productSkus[0].node.product.name,
        price: productSkus[0].node.price,
        metadata: productSkus[0].node.product.metadata,
        image: imageData,
      })
   })
   return merchandise
}

const checkForLocalFiles = skus => skus[0].node.localFiles ? skus[0].node.localFiles[0].childImageSharp.fixed : null

const getProductIds = (data) => {
   let productIds = []
   data.map( d => productIds.push(d.node.product.id))
   productIds = [...new Set(productIds)]
   return productIds
}