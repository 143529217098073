import styled from "styled-components"
import Card from "@material-ui/core/Card"

const ProductCard = styled(Card)`
   margin: 16px 8px;
   background-color: white !important;
   width: 320px;
   min-height: 260px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   color: ${props => props.theme.black} !important;
   & .MuiCardActions-root{
      padding: 16px;
   }
`

export default ProductCard;