import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import StripeContainer from "../components/Products/StripeContainer"
import PageLayout from "../components/layout-page"



const Store = () => {
   const data = useStaticQuery(graphql`
      query {
         headerImage: file(
            relativePath: { eq: "person-holding-barbell-841130.jpg" }
         ){
            childCloudinaryAsset {
               fluid(maxWidth: 1260) {
                  ...CloudinaryAssetFluid
               }
            }
         }
      }
   `)
   return (
     <Layout>
       <SEO title="Store" />
       <PageLayout title="Store">
         <Img
           sizes={data.headerImage.childCloudinaryAsset.fluid}
           style={{ margin: "0 auto" }}
         />
         <StripeContainer />
       </PageLayout>
     </Layout>
   )
}


export default Store
