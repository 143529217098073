import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import TemplateCard from "./TemplateCard"

const SkusContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 0 1rem 0;
`

const TemplateSkus = ({stripe}) => {
   const data = useStaticQuery(graphql`
     {
       allStripeSku(
         filter: { product: { metadata: { type: { eq: "template" } } } }
       ) {
         edges {
           node {
             id
             attributes {
               name
             }
             localFiles {
               id
               childImageSharp {
                 id
                 fluid {
                    ...GatsbyImageSharpFluid
                 }
                 fixed (width: 320) {
                    ...GatsbyImageSharpFixed
                 }
               }
             }
             currency
             price
             product {
               metadata {
                 type
                 description
               }
             }
           }
         }
       }
     }
   `)
   const templates = data.allStripeSku.edges
   return (
      <SkusContainer id="template-container">
         {
            templates && templates.map(({node: sku}) => (
               <TemplateCard
                  key={sku.id}
                  sku={sku}
                  stripe={stripe}
               />
            ))
         }
      </SkusContainer>
   )
}

export default TemplateSkus;