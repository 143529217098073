import React from "react"

import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import CheckoutButton from '../styles/PrimaryButton'
import ProductCard from '../styles/ProductCard'
import ImageContainer from '../styles/ImageContainer'
import {formatPrice} from './product-calcs'

const Product = ({sku, onClick, children}) => {
   return (
      <ProductCard>
         <CardContent>
            <ImageContainer>
               {children}
            </ImageContainer>
            <Typography component="h2" variant="h5" align='center'>
               {sku.attributes.name}
            </Typography>
            <Typography variant="subtitle1" color="initial" align='center'>
                {formatPrice(sku.price, sku.currency)}
            </Typography>
            <Typography variant="body1">
                {sku.product.metadata.description}
            </Typography>
         </CardContent>
         <CardActions>
            <CheckoutButton
               onClick={onClick}
               variant="contained"
               fullWidth
               size='large'
            >
               Buy Now!
            </CheckoutButton>
         </CardActions>
      </ProductCard>
   )
};

export default Product;
