import React, { Component } from "react"
import Skus from "./Skus"



class StripeContainer extends Component {
  state = {
    stripe: null,
  }
  componentDidMount() {
    const stripe = window.Stripe("pk_live_M2UuPqbdn5cSVBVohjHV2QTe00DapCTZdx")
    this.setState({ stripe })
  }
  render() {
      return (
         <Skus stripe={this.state.stripe} />
      )
  }
}

export default StripeContainer;
