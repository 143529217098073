import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import MerchCard from "./MerchCard"
import * as MERCH from "./product-calcs"

const SkusContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 0 1rem 0;
`

const MerchSkus = ({stripe}) => {
   const data = useStaticQuery(graphql`
      {
         allStripeSku(
           filter: { product: { metadata: { type: { eq: "merchandise" } } } }
         ) {
            edges {
               node {
                  localFiles {
                     childImageSharp {
                        fixed(height: 250) {
                           ...GatsbyImageSharpFixed
                        }
                     }
                  }
                  id
                  currency
                  attributes {
                     name
                  }
                  price
                  product {
                     id
                     name
                     metadata {
                        description
                        type
                     }
                  }
               }
            }
         }
      }
   `)
   const merchandise = MERCH.getData(data.allStripeSku.edges)
   return (
      <SkusContainer id="merchandise-container">
         {
            merchandise && merchandise.map( product => (
               <MerchCard
                  key={product.id}
                  product={product}
                  skus={product.skus}
                  stripe={stripe}
               />
            ))
         }
      </SkusContainer>
   )
}

export default MerchSkus;
